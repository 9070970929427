html {
  width: 100%;
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

body, .base, #root, .card {
  height: 100%;
}

header {
  background-color: #3caef8;
  padding: 20px 10px;
  color: white;
  font-size: 25px;
}

#hook {
  width: 100%;
  display: flex;
  justify-content: center;
}

.base {
  margin: 0 auto;
  width: 100%;
  height: calc(100% - 69.9px);
}

textarea {
  padding: 10px;
  box-sizing: border-box;
  border: none;
  height: calc(100% - 40px);
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

.button {
  width: 100%;
  background-color: #3caef8;
  color: white;
  text-align: center;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.card {
  width: 100%;
  box-sizing: border-box;
  display: flex;
}

.card > div {
  flex: 1;
  max-width: 50%;
}

.card > div:first-of-type {
  border-right: 2px solid #2c3037;
}

.converter {
  display: flex;
  flex-flow: column;
  width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.display {
  display: flex;
  flex-flow: column;
}

.display > div {
  display: flex;
  flex-flow: column;
  flex: 1;
  max-height: 50%;
}

.display > div:first-of-type {
  border-bottom: 1px solid #2c3037;
}

.display > div span {
  padding: 10px;
  height: 100%;
  overflow: scroll;
}

.display > div ul {
  padding: 10px;
  height: 100%;
  overflow: scroll;
}

.header {
  background-color: #2c3037;
  height: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  color: white;
}

.header .button {
  max-width: 150px;
}

.parsed span {
  overflow-wrap: break-word;
}